<template>
  <div>
    <div v-if="sidebarData.length !== 0 && !sidebarLoading">
      <div
        v-if="Object.keys(sidebarData[0][sidebarDataField]).length > 0"
        class="d-flex justify-content-between"
      >
        <strong>{{ $t(`stats.${sidebarDataField}`) }}:</strong>
        <strong v-if="sidebarDataField === 'revenue'">
          <!--{{ currency(accountCurrency, countObjectTotals(sidebarData[0][sidebarDataField])) }}-->
          <div
            v-for="(currencyType, idx) in Object.entries(calculateTotalRevenue(sidebarData[0][sidebarDataField]))"
            :key="idx"
          >
            {{ currency(currencyType[0], currencyType[1]) }}
          </div>
        </strong>
        <strong v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(countObjectTotals(sidebarData[0][sidebarDataField])) }}</strong>
      </div>
      <div
        v-else
        class="d-flex justify-content-between"
      >
        <strong>{{ $t(`stats.${sidebarDataField}`) }}:</strong>
        <strong v-if="sidebarDataField === 'revenue'">{{ currency(accountCurrency, sidebarData[0][sidebarDataField]) }}</strong>
        <strong v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(sidebarData[0][sidebarDataField]) }}</strong>
      </div>
      <div
        v-for="item in sidebarData"
        :key="item.date"
      >
        <div v-if="typeof item[sidebarDataField] === 'object' && Object.keys(item).length > 0">
          <div
            v-for="(value, name, idx) in sortObjectByValues(item[sidebarDataField])"
            :key="`${sidebarDataField}${idx}`"
            :class="{
              even: idx % 2 !== 0,
              odd: idx % 2 === 0
            }"
          >
            <div
              v-if="name !== 'others'"
              class="stats-item d-flex justify-content-between"
            >
              <template v-if="sidebarDataType === 'banner' || sidebarDataType === 'cpcfeed'">
                <small v-if="campaignUserIdByIdFromStore(name) === null">
                  {{ campaignNameByIdFromStore(name) }}
                </small>
                <template v-else>
                  <small
                    :class="{'text-primary cursor-pointer': name !== 'others'}"
                    @click="openRouteInNewWindow('dashboard-user-campaign', { id: name, userId: campaignUserIdByIdFromStore(name) })"
                  >
                    {{ campaignNameByIdFromStore(name) }}
                  </small>
                </template>
              </template>
              <template v-else-if="sidebarDataType === 'external'">
                <!-- @click="$router.push({ name: 'dashboard-partner', params: { id: name } })" /-->
                <small
                  :class="{'text-primary cursor-pointer': name !== 'others'}"
                  @click="openRouteInNewWindow('dashboard-partner', { id: name })"
                >
                  {{ partnerNameByIdFromStore(name) }}
                </small>
              </template>
              <template v-else-if="sidebarDataType === 'prebid'">
                <!-- @click="$router.push({ name: 'dashboard-prebidpartner', params: { id: name } })" /-->
                <small
                  :class="{'text-primary cursor-pointer': name !== 'others'}"
                  @click="openRouteInNewWindow('dashboard-prebidpartner', { id: name })"
                >
                  {{ name }}
                </small>
              </template>
              <template v-else>
                <small>{{ name }}</small>
              </template>
              <small v-if="sidebarDataField === 'revenue'">{{ currency(campaignCurrencyByIdFromStore(name), value) }}</small>
              <small v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(value) }}</small>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="sidebarDataType === 'external'"
        class="alert alert-primary mt-2"
      >
        <div class="alert-body">
          <span><strong>{{ $t(`stats.alerts.${sidebarDataType}`) }}</strong></span>
        </div>
      </div>
    </div>
    <div
      v-else-if="sidebarLoading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </div>
  </div>
</template>

<script>
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate from '@/utils/date-formatter'
import { mapGetters } from 'vuex'
import { getSiteById, openRouteInNewWindow } from '@/utils/helpers'

import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  props: {
    sidebarData: {
      type: Array,
      required: true,
    },
    accountCurrency: {
      type: String,
      default: '',
    },
    sidebarDataField: {
      type: String,
      default: '',
    },
    sidebarDataType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      getFormattedDate,
      getSiteById,
      openRouteInNewWindow,
      sidebarLoading: false,
      toggleData: {
        revenue: [],
        clicks: [],
      },
    }
  },
  computed: {
    ...mapGetters('dashboard-campaings-stats', ['campaignNameByIdFromStore', 'campaignUserIdByIdFromStore', 'campaignCurrencyByIdFromStore']),
    ...mapGetters('dashboard-sites-stats', ['siteNameByIdFromStore']),
    ...mapGetters('dashboard-partners-stats', ['partnerNameByIdFromStore']),
  },
  watch: {
    sidebarData(newValue) {
      if (newValue.length > 0) {
        this.sidebarLoading = false
      } else {
        this.sidebarLoading = true
        setTimeout(() => { this.sidebarLoading = false }, 1000)
      }
    },
  },
  methods: {
    filteredFields(item) {
      const { clicks, revenue, impressions } = item

      return {
        clicks,
        revenue,
        impressions,
      }
    },
    countObjectTotals(obj) {
      const calc = Object.values(obj).reduce((acc, value) => acc + value, 0)
      return calc.toFixed(2)
    },
    sortObjectByValues(obj) {
      const entries = Object.entries(obj)
      const othersEntry = entries.find(entry => entry[0] === 'others')
      const filteredEntries = entries.filter(entry => entry[0] !== 'others')
      filteredEntries.sort((a, b) => b[1] - a[1])
      if (othersEntry) {
        filteredEntries.push(othersEntry)
      }

      return Object.fromEntries(filteredEntries)
    },
    formatTitle(key) {
      if (key !== 'revenue') {
        return `${this.$i18n.t(`stats.${key}`)} ${this.countObjectTotals(this.sidebarData[key])}`
      }

      return `${key} ${this.currency(this.accountCurrency, this.countObjectTotals(this.sidebarData[key]))}`
    },
    toggleDetails(value, field) {
      const idx = this.toggleData[field].indexOf(value)

      if (idx !== -1) {
        this.toggleData[field].splice(idx, 1)
      } else {
        this.toggleData[field].push(value)
      }
    },
    calculateTotalRevenue(data) {
      const revenueByCurrency = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const [id, amount] of Object.entries(data)) {
        const currencyType = this.campaignCurrencyByIdFromStore(id)
        if (currencyType !== null) {
          if (!revenueByCurrency[currencyType]) {
            revenueByCurrency[currencyType] = 0
          }
          revenueByCurrency[currencyType] += amount
        }
      }

      return revenueByCurrency
    },
  },
}
</script>

<style scoped>
#modal-stats .stats-item {
  padding: 4px;
  border-top: 1px solid #ebe9f1
}

#modal-stats .stats-item.odd {
  background-color: #fafafc;
}

#modal-stats .card .card-body .stats-item.even {
  background-color: #ffffff;
}
</style>
