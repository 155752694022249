import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORAGE_KEY = 'siteNames'
const TIMESTAMP_KEY = 'siteNamesTimestamp'
const EXPIRATION_TIME = 60 * 60 * 1000

export default {
  namespaced: true,
  state: {
    siteNames: JSON.parse(localStorage.getItem(STORAGE_KEY)) || [],
  },
  getters: {
    sites: state => {
      const lastSavedTime = localStorage.getItem(TIMESTAMP_KEY)
      const currentTime = Date.now()

      if (!lastSavedTime || Number.isNaN(lastSavedTime) || currentTime - Number(lastSavedTime) > EXPIRATION_TIME) {
        return []
      }

      return state.siteNames
    },
    siteNameByIdFromStore: state => siteId => {
      const lastSavedTime = localStorage.getItem(TIMESTAMP_KEY)
      const currentTime = Date.now()

      if (!lastSavedTime || Number.isNaN(lastSavedTime) || currentTime - Number(lastSavedTime) > EXPIRATION_TIME) {
        return siteId
      }

      const site = state.siteNames.find(s => s.id === siteId)
      return site ? site.name : siteId
    },
  },
  mutations: {
    ADD_SITES_NAME(state, newSites) {
      const currentIds = new Set(state.siteNames.map(site => site.id))

      newSites.forEach(newSite => {
        if (!currentIds.has(newSite.id)) {
          state.siteNames.push(newSite)
        }
      })

      localStorage.setItem(STORAGE_KEY, JSON.stringify(state.siteNames))
      localStorage.setItem(TIMESTAMP_KEY, Date.now().toString())
    },
  },
  actions: {
    async fetchSiteNamesById({ commit, state }, ids) {
      const lastSavedTime = localStorage.getItem(TIMESTAMP_KEY)
      const timestamp = Number(lastSavedTime)
      const currentTime = Date.now()

      if (!lastSavedTime || Number.isNaN(timestamp) || currentTime - timestamp > EXPIRATION_TIME) {
        try {
          const response = await useJwt.siteNamesById(ids)
          commit('ADD_SITES_NAME', response.data)
        } catch (error) {
          if (error.response && error.response.status === 422 && error.response.data.errors.length) {
            error.response.data.errors.forEach(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: err.detail,
                },
              })
            })
          }
        }
      } else {
        const missingIds = ids.filter(id => !state.siteNames.some(site => site.id === id))

        if (missingIds.length === 0) {
          return
        }

        try {
          const response = await useJwt.siteNamesById(missingIds)
          commit('ADD_SITES_NAME', response.data)
        } catch (error) {
          if (error.response && error.response.status === 422 && error.response.data.errors.length) {
            error.response.data.errors.forEach(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: err.detail,
                },
              })
            })
          }
        }
      }
    },
  },
}
