var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Numbers are not final! It may take up to 24h for daily numbers to update!")])])]),_c('b-table',{attrs:{"small":"","items":_vm.statsData,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-direction":_vm.sortDirection,"responsive":"","striped":"","foot-clone":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"head(date)",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('stats.date'))+" ")]},proxy:true},{key:"head(impressions)",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('stats.shows'))+" ")]},proxy:true},{key:"head(revenue)",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('stats.amount'))+" ")]},proxy:true},{key:"cell(date)",fn:function(row){return [(row)?_c('span',[_c('a',{class:row.item._showDetails === true ? 'text-secondary' : '',attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showDetails(row)}}},[_c('strong',[_vm._v(_vm._s(_vm.getFormattedDate(new Date(row.item.date))))]),_c('feather-icon',{staticClass:"ml-15",attrs:{"icon":row.item._showDetails === true ? 'ChevronDownIcon' : 'ChevronRightIcon',"size":"20"}})],1)]):_c('span',[_vm._v(" "+_vm._s(row.item.date)+" ")])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [(item)?_c('div',[(_vm.loading_hourly && !_vm.statsDay[item.date])?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):(!_vm.loading_hourly && _vm.statsDay[item.date].length)?_c('b-table',{attrs:{"small":"","items":_vm.statsDay[item.date],"fields":_vm.fields,"responsive":"","striped":"","thead-class":"d-none","table-variant":"primary"},scopedSlots:_vm._u([{key:"cell(date)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getFormattedDateTimeZone(row.item.date))+" ")]}},{key:"cell(impressions)",fn:function(data){return [_vm._v(" "+_vm._s(data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0)+" ")]}},{key:"cell(clicks)",fn:function(data){return [_vm._v(" "+_vm._s(data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0)+" ")]}},{key:"cell(revenue)",fn:function(data){return [_vm._v(" "+_vm._s(data.value ? _vm.currency(_vm.accountCurrency, data.value) : 0)+" ")]}}],null,true)}):_vm._e()],1):_vm._e()]}},{key:"cell(revenue)",fn:function(data){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleClick('revenue', data.item.date)}}},[_vm._v(" "+_vm._s(data.value ? _vm.currency(_vm.accountCurrency, data.value) : 0)+" "),_c('feather-icon',{staticClass:"ml-15",attrs:{"icon":_vm.toggleData['revenue'].includes(data.item.date) === true ? 'ChevronDownIcon' : 'ChevronRightIcon',"size":"20"}})],1),(_vm.toggleData['revenue'].includes(data.item.date))?_c('div',_vm._l((_vm.showStatsRevenue('revenue', data.item.date)),function(value,name){return _c('div',{key:value},[(name !== 'adsense')?_c('small',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){name !== 'banner' && name !== 'cpcfeed'
              ? _vm.showSiteRevenueDetails(name, data.item.date, 'revenue')
              : name !== 'cpcfeed'
                ? _vm.showCampaignRevenueDetails(name, data.item.date, 'revenue')
                : _vm.showCPCFeedDetails(name, data.item.date, 'revenue')}}},[_vm._v(" "+_vm._s(_vm.$t(("stats.adstypes." + name)))+": "+_vm._s(_vm.currency(_vm.accountCurrency, value))+" ")]):_c('small',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({name: 'adsense-incomes'})}}},[_vm._v(" "+_vm._s(_vm.$t(("stats.adstypes." + name)))+": "+_vm._s(_vm.currency(_vm.accountCurrency, value))+" ")])])}),0):_vm._e()]}},{key:"cell(impressions)",fn:function(data){return [_vm._v(" "+_vm._s(data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0)+" ")]}},{key:"cell(clicks)",fn:function(data){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleClick('clicks', data.item.date)}}},[_vm._v(" "+_vm._s(data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0)+" "),_c('feather-icon',{staticClass:"ml-15",attrs:{"icon":_vm.toggleData['clicks'].includes(data.item.date) === true ? 'ChevronDownIcon' : 'ChevronRightIcon',"size":"20"}})],1),(_vm.toggleData['clicks'].includes(data.item.date))?_c('div',_vm._l((_vm.showStatsRevenue('clicks', data.item.date)),function(value,name,idx){return _c('div',{key:("clicks" + idx)},[_c('small',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){name !== 'banner' && name !== 'cpcfeed'
              ? _vm.showSiteRevenueDetails(name, data.item.date, 'clicks')
              : name !== 'cpcfeed'
                ? _vm.showCampaignRevenueDetails(name, data.item.date, 'clicks')
                : _vm.showCPCFeedDetails(name, data.item.date, 'clicks')}}},[_vm._v(" "+_vm._s(_vm.$t(("stats.adstypes." + name)))+": "+_vm._s(new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(value))+" ")])])}),0):_vm._e()]}},{key:"foot(date)",fn:function(data){return [_c('strong',{attrs:{"data-label":data.label}},[_vm._v(_vm._s(_vm.$t('Records'))+": "+_vm._s(_vm.statsData.length))])]}},{key:"foot(clicks)",fn:function(data){return [_c('span',{staticStyle:{"font-weight":"bold","cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.toggleTotals('clicks')}}},[_c('strong',{attrs:{"data-label":data.label}},[_vm._v(_vm._s(new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(_vm.totalFormat('clicks'))))]),_c('feather-icon',{staticClass:"ml-15",attrs:{"icon":_vm.toggleTotalsData['clicks'] ? 'ChevronDownIcon' : 'ChevronRightIcon',"size":"20"}})],1),(_vm.toggleTotalsData['clicks'])?_c('div',_vm._l((_vm.statsRevenueRange.clicks),function(value,name){return _c('div',{key:value},[_c('small',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){name !== 'banner' && name !== 'cpcfeed'
              ? _vm.showSiteRevenueTotalDetails(name, _vm.selectedRange.from, 'clicks')
              : name !== 'cpcfeed'
                ? _vm.showCampaignRevenueTotalDetails(name, _vm.selectedRange.from, 'clicks')
                : _vm.showCPCFeedTotalDetails(name, _vm.selectedRange.from, 'clicks')}}},[_c('strong',{staticStyle:{"text-transform":"initial"}},[_vm._v(_vm._s(_vm.$t(("stats.adstypes." + name)))+": "+_vm._s(new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(value)))])])])}),0):_vm._e()]}},{key:"foot(impressions)",fn:function(data){return [_c('strong',{attrs:{"data-label":data.label}},[_vm._v(_vm._s(new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(_vm.totalFormat('impressions'))))])]}},{key:"foot(revenue)",fn:function(data){return [_c('span',{staticStyle:{"font-weight":"bold","cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.toggleTotals('revenue')}}},[_c('strong',{attrs:{"data-label":data.label}},[_vm._v(_vm._s(_vm.currency(_vm.accountCurrency, _vm.totalFormat('revenue'))))]),_c('feather-icon',{staticClass:"ml-15",attrs:{"icon":_vm.toggleTotalsData['revenue'] ? 'ChevronDownIcon' : 'ChevronRightIcon',"size":"20"}})],1),(_vm.toggleTotalsData['revenue'])?_c('div',_vm._l((_vm.statsRevenueRange.revenue),function(value,name){return _c('div',{key:value},[_c('small',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){name !== 'banner' && name !== 'cpcfeed'
              ? _vm.showSiteRevenueTotalDetails(name, _vm.selectedRange.from, 'revenue')
              : name !== 'cpcfeed'
                ? _vm.showCampaignRevenueTotalDetails(name, _vm.selectedRange.from, 'revenue')
                : _vm.showCPCFeedTotalDetails(name, _vm.selectedRange.from, 'revenue')}}},[_c('strong',{staticStyle:{"text-transform":"initial"}},[_vm._v(_vm._s(_vm.$t(("stats.adstypes." + name)))+": "+_vm._s(_vm.currency(_vm.accountCurrency, value)))])])])}),0):_vm._e()]}}])}),_c('b-modal',{ref:"modal-stats",attrs:{"id":"modal-stats","centered":"","size":"lg","title":_vm.modalTitle,"ok-only":"","ok-title":"Ok","scrollable":""}},[_c('SiteStatsModal',{attrs:{"account-currency":_vm.accountCurrency,"sidebar-data":_vm.sidebarData,"sidebar-data-field":_vm.sidebarDataField,"sidebar-data-type":_vm.sidebarDataType}})],1),_c('b-modal',{ref:"modal-stats-totals",attrs:{"id":"modal-stats-totals","centered":"","size":"lg","title":_vm.modalTitleTotals,"ok-only":"","ok-title":"Ok","scrollable":""}},[_c('SiteStatsModalTotals',{attrs:{"account-currency":_vm.accountCurrency,"sidebar-data":_vm.sidebarDataTotals,"sidebar-data-field":_vm.sidebarDataField,"sidebar-data-type":_vm.sidebarDataType,"selected-range":_vm.selectedRange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }