<template>
  <div>
    <div
      v-if="Object.keys(banner).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <UserToolbar
        v-if="account && Object.keys(account).length !== 0"
        :account="account"
      />
      <b-card>
        <CreativeToolbar
          v-if="Object.keys(banner).length !== 0"
          :banner="banner"
          closing
        />
      </b-card>
      <b-form @submit.prevent>
        <validation-observer ref="validationBannerRules">
          <b-tabs
            pills
          >
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Main settings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('campaigns.title')"
                      label-for="i-title"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-title"
                            v-model="banner.title"
                            :placeholder="$t('banners.exampletitle')"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Url"
                      label-for="i-url"
                    >
                      <div
                        v-if="campaign.enable_advertorial"
                        role="alert"
                        aria-live="polite"
                        aria-atomic="true"
                        class="alert alert-primary"
                      >
                        <div class="alert-body text-center">
                          <span>{{ $t('banners.url_generated_automatically') }}</span>
                        </div>
                      </div>
                      <validation-provider
                        v-if="!campaign.enable_advertorial"
                        #default="{ errors }"
                        name="Url"
                        :rules="{
                          required: true,
                          regex: /^(?:(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*|tg:\/\/resolve\?domain=[a-zA-Z0-9]+)$/,
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="Link2Icon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-url"
                            v-model="banner.url"
                            placeholder="url"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <!-- image -->
                    <div>
                      <h4>{{ $t('campaigns.picture') }}</h4>
                    </div>
                    <b-media no-body>
                      <b-media-aside>
                        <b-link @click="$refs.refInputEl.$el.click()">
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="`${$creativeImgUrl}/${banner.image_id}.jpg`"
                            height="80"
                          />
                        </b-link>
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <!-- upload button -->
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="$refs.refInputEl.$el.click()"
                        >
                          {{ $t('banners.chooseimage') }}
                        </b-button>
                        <b-form-file
                          ref="refInputEl"
                          v-model="mediaFile"
                          accept=".jpg, .png, .gif"
                          :hidden="true"
                          plain
                          @input="uploadBannerImg"
                        />
                        <b-card-text>Allowed JPG, GIF or PNG. Max size of 1Mb</b-card-text>
                      </b-media-body>
                    </b-media>
                    <!--/ image -->

                    <!-- video -->
                    <div
                      v-if="banner.type === 'video'"
                      class="mt-2"
                    >
                      <div>
                        <h4>{{ $t('Video') }}</h4>
                      </div>
                      <div
                        v-if="banner.video_source_id && banner.video_source_id !== ''"
                        class="mb-1"
                        :style="{
                          maxWidth: '500px',
                        }"
                      >
                        <b-embed
                          type="iframe"
                          aspect="16by9"
                          :src="`${this.$promotionPageVideoUrl}/${banner.video_source_id}`"
                          allowfullscreen
                        />
                      </div>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="$refs.refInputElVideo.$el.click()"
                      >
                        {{ $t('banners.choosevideo') }}
                      </b-button>
                      <b-form-file
                        ref="refInputElVideo"
                        v-model="videoFile"
                        accept=".mp4"
                        :hidden="true"
                        plain
                        @input="uploadBannerVideo"
                      />
                      <b-card-text>Allowed MP4. Max size of 800kB</b-card-text>
                    </div>
                    <!--/ video -->
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('campaigns.status')"
                      label-for="i-status"
                    >
                      <b-form-select
                        v-model="banner.status"
                        :options="statuses"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${banner.user_id}/campaigns/${campaign._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="bannerUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ general tab -->

            <b-tab v-if="authUserData.role.includes('admin')">
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="ShieldIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('menu.admin') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('sites.adminsettings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group class="mt-2">
                      <b-form-checkbox
                        v-model="is_moderate"
                        value="1"
                      >
                        {{ $t('sites.moderated') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('campaigns.type')"
                      label-for="i-type"
                    >
                      <b-form-select
                        v-model="banner.type"
                        :options="campaignType"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${banner.user_id}/campaigns/${campaign._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="bannerUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ admin tab -->

            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="TargetIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('banners.targetings.title') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('banners.targetings.title') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.date')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.date.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('banners.targetings.startdate')"
                        for="i-startdate"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              size="16"
                            />
                          </b-input-group-prepend>
                          <flat-pickr
                            id="i-startdate"
                            v-model="startdate"
                            :config="{
                              locale: flatPickrLocales[$i18n.locale],
                              altInput: true,
                              altFormat: 'd-m-Y',
                              dateFormat: 'Y-m-d',
                            }"
                            class="form-control flat-picker bg-transparent shadow-none"
                            placeholder="DD-MM-YYYY"
                          />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group
                        :label="$t('banners.targetings.enddate')"
                        for="i-startdate"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              size="16"
                            />
                          </b-input-group-prepend>
                          <flat-pickr
                            id="i-enddate"
                            v-model="enddate"
                            :config="{
                              locale: flatPickrLocales[$i18n.locale],
                              altInput: true,
                              altFormat: 'd-m-Y',
                              dateFormat: 'Y-m-d',
                            }"
                            class="form-control flat-picker bg-transparent shadow-none"
                            placeholder="DD-MM-YYYY"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.date_num.label')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.date_num.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                      </b-form-group>

                      <b-form-group
                        for="i-date_num"
                      >
                        <flat-pickr
                          id="i-date_num"
                          v-model="date_num"
                          :config="{
                            locale: flatPickrLocales[$i18n.locale],
                            mode: 'multiple',
                            inline: true,
                            dateFormat: 'Y-m-d',
                          }"
                          class="form-control flat-picker bg-transparent shadow-none"
                          placeholder="DD-MM-YYYY"
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.weekday')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.weekday.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-weekday"
                          v-model="weeks"
                          :class="statusVariant(banner.targetings.weekday.status)"
                          :placeholder="$t('banners.targetings-selects.weekday')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="weeks_days"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.hour')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.hour.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-hour"
                          v-model="hours"
                          :class="statusVariant(banner.targetings.hour.status)"
                          :placeholder="$t('banners.targetings-selects.hour')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="hours_nums"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.device')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.device.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-devices"
                          v-model="selctedDevices"
                          :class="statusVariant(banner.targetings.device.status)"
                          :placeholder="$t('banners.targetings-selects.device')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="devices"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.os')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.os.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-os"
                          v-model="selctedOs"
                          :class="statusVariant(banner.targetings.os.status)"
                          :placeholder="$t('banners.targetings-selects.os')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="os"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.browser')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.browser.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-browser"
                          v-model="selctedBrowsers"
                          :class="statusVariant(banner.targetings.browser.status)"
                          :placeholder="$t('banners.targetings-selects.browser')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="browsers"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.language')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.language.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-language"
                          v-model="selctedLanguages"
                          :class="statusVariant(banner.targetings.language.status)"
                          :placeholder="$t('sites.chooselanguage')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="langcode"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.country')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.country.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <v-select
                          id="i-countries"
                          v-model="selectedCountries"
                          :class="statusVariant(banner.targetings.country.status)"
                          :placeholder="$t('sites.choosecountry')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="countriesAllFormated"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.city')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.city.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-language"
                          v-model="selectedCities"
                          :class="statusVariant(banner.targetings.city.status)"
                          :placeholder="$t('banners.targetings-selects.city')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="citiesCountry"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.sites')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group
                        label-for="i-sites"
                      >
                        <b-form-radio-group
                          v-model="banner.targetings.site.status"
                          class="mb-1"
                          :class="statusVariant(banner.targetings.site.status)"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="HashIcon" />
                          </b-input-group-prepend>
                          <b-form-textarea
                            id="i-sites"
                            v-model="selectedSites"
                            placeholder="Ids"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.pixel_targeting')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group
                        label-for="i-remarketing"
                      >
                        <b-form-radio-group
                          v-model="banner.targetings.pixel_targeting.status"
                          class="mb-1"
                          :class="statusVariant(banner.targetings.pixel_targeting.status)"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="Link2Icon" />
                          </b-input-group-prepend>
                          <b-form-textarea
                            id="i-pixel_targeting"
                            v-model="pixel_targeting"
                            placeholder="Urls"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.capping')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.capping.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />

                        <b-input-group
                          class="input-group-merge"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="ClockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-capping"
                            v-model="selectedCapping"
                            :placeholder="$t('banners.targetings.hours')"
                            type="number"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.site_language')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.site_language.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-site_language"
                          v-model="selctedSiteLanguages"
                          :class="statusVariant(banner.targetings.site_language.status)"
                          :placeholder="$t('sites.chooselanguage')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="languages"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    v-if="authUserData.role.includes('admin')"
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('DSP partners')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="banner.targetings.dsppartner.status"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="HashIcon" />
                          </b-input-group-prepend>
                          <b-form-textarea
                            id="i-dsppartner"
                            v-model="selectedDspPartners"
                            placeholder="DSP partner ID"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                  >
                    <b-card
                      border-variant="primary"
                      :title="$t('banners.targetings.widget_type')"
                      bg-variant="transparent"
                      class="shadow-none"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="bannerTargetingsWidgetTypeStatus"
                          class="mb-1"
                          button-variant="outline-primary"
                          :options="optionsRadioLocale"
                          buttons
                          size="sm"
                          name="radios-btn-default"
                        />
                        <v-select
                          id="i-site_language"
                          v-model="selectedWidgetTypes"
                          :class="statusVariant(bannerTargetingsWidgetTypeStatus)"
                          :placeholder="$t('sites.choosetype')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="widgetTypesLocale"
                          label="text"
                          multiple
                        />
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <!-- <b-col
                    class="text-right mb-2"
                    md="12"
                  >
                    <small>* to enable targeting hit checkbox ✔</small>
                  </b-col> -->
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${banner.user_id}/campaigns/${campaign._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="bannerUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ targetings tab -->
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import langcode from '@/libs/langcode'
import countriesAll from '@/libs/countries'
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BForm,
  BFormGroup,
  BFormRadioGroup,
  // BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  BSpinner,
  VBTooltip,
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BEmbed,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import CreativeToolbar from '@/views/dashboard/creatives/CreativeToolbar.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import Ripple from 'vue-ripple-directive'
import wait from '@/utils/wait'
import { getUserData } from '@/auth/utils'
import { applyTimeZoneOffset } from '@/utils/date-formatter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  Ukrainian,
  Russian,
  English,
  Portuguese,
} from '@core/libs/flatpickr/l10n/locales'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BCardText,
    Breadcrumbs,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    vSelect,
    flatPickr,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BEmbed,
    UserToolbar,
    CreativeToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pageTitle: '',
      pageSubtitle: '',
      mediaFile: [],
      videoFile: [],
      is_moderate: [],
      min,
      required,
      integer,
      account: {},
      authUserData: getUserData(),
      campaign: [],
      banner: [],
      weeks: [],
      hours: [],
      selectedWidgetTypes: [],
      selctedDevices: [],
      selctedOs: [],
      selctedBrowsers: [],
      selctedLanguages: [],
      selectedCountries: [],
      selectedCities: [],
      selctedSiteLanguages: [],
      selectedSites: null,
      pixel_targeting: null,
      selectedDspPartners: null,
      selectedCapping: null,
      breadcrumbItems: [],
      startdate: null,
      enddate: null,
      date_num: null,
      offset_time: null,
      ...options,
      langcode,
      countriesAll,
      flatPickrLocales: {
        en: English,
        ru: Russian,
        ua: Ukrainian,
        pt: Portuguese,
      },
      widget_types_local: [
        { value: 'builder', text: 'selects.widgettype.builder' },
        { value: 'default', text: 'selects.widgettype.default' },
        { value: 'infinite', text: 'selects.widgettype.infinite' },
        { value: 'slide', text: 'selects.widgettype.slide' },
        { value: 'position_fixed', text: 'selects.widgettype.position_fixed' },
      ],
      bannerTargetingsWidgetTypeStatus: 'inactive',
      applyTimeZoneOffset,
      targetings: {
        widget_type: {
          processor: 'core.targetings.WidgetTypeTargeting',
          params: {
            widget_types: null,
          },
          status: 'inactive',
        },
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        negative     : 'outline-primary',
        active       : 'outline-success',
        inactive     : 'outline-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    countriesAllFormated() {
      const formated = Object.keys(this.countriesAll).map(c => ({ text: c, value: c, cities: this.countriesAll[c] }))
      return formated
    },
    citiesCountry() {
      let allCities = []
      if (this.selectedCountries.length !== 0) {
        this.selectedCountries.forEach(c => {
          if (this.countriesAll[c.value] !== undefined) {
            const cities = this.countriesAll[c.value].map(cc => ({ text: cc, value: cc }))
            allCities.push(...cities)
          }
        })
        allCities = allCities.filter((item, index, self) => index === self.findIndex(obj => obj.value === item.value)) // remove duplicates
      } else {
        allCities = []
      }
      return allCities
    },
    optionsRadioLocale() {
      return this.optionsRadio.map(o => ({
        value: o.value,
        text: this.$t(`banners.targetings-selects.${o.value}`),
      }))
    },
    widgetTypesLocale() {
      return this.widget_types_local.map(t => ({ value: t.value, text: this.$i18n.t(t.text) }))
    },
  },
  created() {
    this.getUser(this.$route.params.userId)
  },
  methods: {
    async getBannerData() {
      const responseData = await useJwt.getBanner(this.$route.params.bannerId)
      this.banner = responseData.data.banner || []
      if (this.banner.is_moderate) {
        this.is_moderate.push(1)
      }
      if (this.banner.targetings.date.params.startdate !== '') {
        this.startdate = new Date(this.banner.targetings.date.params.startdate)
      }

      if (this.banner.targetings.date.params.enddate !== '') {
        this.enddate = new Date(this.banner.targetings.date.params.enddate)
      }

      if (this.banner.targetings.date_num.params.dates.length !== 0) {
        this.date_num = this.banner.targetings.date_num.params.dates.map(dn => new Date(dn).toISOString()).join(', ')
      }

      const { weekdays } = this.banner.targetings.weekday.params
      if (weekdays.length !== 0) {
        this.weeks = weekdays.map(w => ({ value: w, text: w }))
      }

      if (this.banner.targetings?.widget_type) {
        this.bannerTargetingsWidgetTypeStatus = this.banner.targetings.widget_type.status
        const { widget_types } = this.banner.targetings.widget_type.params
        // eslint-disable-next-line camelcase
        if (widget_types !== null && widget_types.length !== 0) {
          this.selectedWidgetTypes = widget_types.map(w => ({ value: w, text: w }))
        }
      } else {
        this.banner.targetings.widget_type = {
          processor: 'core.targetings.WidgetTypeTargeting',
          params: {
            widget_types: null,
          },
          status: 'inactive',
        }
      }

      const { hours } = this.banner.targetings.hour.params
      if (hours.length !== 0) {
        const hoursNormalize = this.applyTimeZoneOffset(hours, true)
        this.hours = hoursNormalize.map(h => ({ value: h, text: h }))
      }

      const { devices } = this.banner.targetings.device.params
      if (devices.length !== 0) {
        this.selctedDevices = devices.map(h => ({ value: h, text: h }))
      }

      const { os } = this.banner.targetings.os.params
      if (os.length !== 0) {
        this.selctedOs = os.map(h => ({ value: h, text: h }))
      }

      const { browsers } = this.banner.targetings.browser.params
      if (browsers.length !== 0) {
        this.selctedBrowsers = browsers.map(h => ({ value: h, text: h }))
      }

      const { languages } = this.banner.targetings.language.params
      if (languages.length !== 0) {
        this.selctedLanguages = languages.map(h => (this.langcode.find(i => i.value === h)))
      }

      const { sites } = this.banner.targetings.site.params
      if (sites.length) {
        this.selectedSites = sites.join(',')
      } else {
        this.selectedSites = null
      }

      const { dsppartners } = this.banner.targetings.dsppartner.params
      if (dsppartners.length) {
        this.selectedDspPartners = dsppartners.join(',')
      } else {
        this.selectedDspPartners = null
      }

      const capping = this.banner.targetings.capping.params.hours
      if (capping !== '') {
        this.selectedCapping = capping
      }

      const siteLanguages = this.banner.targetings.site_language.params.languages
      if (siteLanguages.length !== 0) {
        this.selctedSiteLanguages = siteLanguages.map(h => ({ value: h, text: h }))
      }

      const { countries } = this.banner.targetings.country.params
      if (countries.length !== 0) {
        this.selectedCountries = countries.map(c => ({ value: c, text: c }))
      }

      const { cities } = this.banner.targetings.city.params
      if (cities.length !== 0) {
        this.selectedCities = cities.map(c => ({ value: c, text: c }))
      }

      // new targeting
      // this.banner.targetings.widget_type.status = 'inactive'

      this.setBreabcrumbs()
    },
    async getCampaignData() {
      const responseData = await useJwt.getCampaign(this.$route.params.id)
      this.campaign = responseData.data.campaign || []
      this.getBannerData()
    },
    async getUser(id) {
      const responseData = await useJwt.getUser(id)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.getCampaignData()
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: this.account.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'Campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.campaign.title,
          to: `/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}`,
        },
        {
          text: this.banner.title,
          to: `/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}/creatives/${this.banner._id}`,
        },
        {
          text: 'Settings',
          active: true,
        },
      ]
    },
    bannerUpdate() {
      this.$refs.validationBannerRules.validate().then(success => {
        if (success) {
          this.banner.is_moderate = this.is_moderate
          this.banner.targetings.date.params.startdate = new Date(this.startdate).toISOString()
          this.banner.targetings.date.params.enddate = new Date(this.enddate).toISOString()
          if (this.date_num !== null) {
            this.banner.targetings.date_num.params.dates = this.date_num.split(',').map(dn => new Date(dn.trim()).toISOString())
          } else {
            this.banner.targetings.date_num.params.dates = []
          }
          if (this.weeks.length !== 0) {
            this.banner.targetings.weekday.params.weekdays = this.weeks.map(w => w.text)
          } else {
            this.banner.targetings.weekday.params.weekdays = null
          }

          this.banner.targetings.widget_type.status = this.bannerTargetingsWidgetTypeStatus
          this.banner.targetings.widget_type.processor = this.targetings.widget_type.processor
          if (this.selectedWidgetTypes.length !== 0) {
            this.banner.targetings.widget_type.params.widget_types = this.selectedWidgetTypes.map(w => w.value)
          } else {
            this.banner.targetings.widget_type.params.widget_types = null
          }
          if (this.hours.length !== 0) {
            this.banner.targetings.hour.params.hours = this.applyTimeZoneOffset(this.hours.map(h => Number(h.text)), false)
          } else {
            this.banner.targetings.hour.params.hours = null
          }

          if (this.selctedDevices.length !== 0) {
            this.banner.targetings.device.params.devices = this.selctedDevices.map(h => h.text)
          } else {
            this.banner.targetings.device.params.devices = null
          }

          if (this.selctedOs.length !== 0) {
            this.banner.targetings.os.params.os = this.selctedOs.map(h => h.text)
          } else {
            this.banner.targetings.os.params.os = null
          }

          if (this.selctedBrowsers.length !== 0) {
            this.banner.targetings.browser.params.browsers = this.selctedBrowsers.map(h => h.text)
          } else {
            this.banner.targetings.browser.params.browsers = null
          }

          if (this.selctedLanguages.length !== 0) {
            this.banner.targetings.language.params.languages = this.selctedLanguages.map(h => h.value)
          } else {
            this.banner.targetings.language.params.languages = null
          }

          if (this.selectedSites !== null && this.selectedSites !== '') {
            this.banner.targetings.site.params.sites = this.selectedSites.split(',').map(s => (s.trim()))
          } else {
            this.banner.targetings.site.params.sites = null
          }

          if (this.pixel_targeting !== null && this.pixel_targeting !== '') {
            this.banner.targetings.pixel_targeting.params.urls = this.pixel_targeting.split(',').map(pixel => (pixel.trim()))
          } else {
            this.banner.targetings.pixel_targeting.params.urls = null
          }

          if (this.selectedDspPartners !== null && this.selectedDspPartners !== '') {
            this.banner.targetings.dsppartner.params.dsppartners = this.selectedDspPartners.split(',').map(dsp => (dsp.trim()))
          } else {
            this.banner.targetings.dsppartner.params.dsppartners = null
          }

          this.banner.targetings.capping.params.hours = this.selectedCapping || null

          if (this.selctedSiteLanguages.length !== 0) {
            this.banner.targetings.site_language.params.languages = this.selctedSiteLanguages.map(l => l.text)
          } else {
            this.banner.targetings.site_language.params.languages = null
          }

          if (this.selectedCountries.length !== 0) {
            this.banner.targetings.country.params.countries = this.selectedCountries.map(c => c.text)
          } else {
            this.banner.targetings.country.params.countries = null
          }

          if (this.selectedCities.length !== 0) {
            this.banner.targetings.city.params.cities = this.selectedCities.map(c => c.text)
          } else {
            this.banner.targetings.city.params.cities = null
          }

          useJwt.setBanner(this.banner._id, this.banner).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Banner saved',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'dashboard-campaigns-creatives-table' })
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.errors[0].detail,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    async uploadBannerImg() {
      const formData = new FormData()
      formData.append('files', this.$refs.refInputEl.files[0])
      const response = await useJwt.uploadBannerImg(this.banner._id, formData)
        .then(wait(1500))
      if (response) {
        this.banner.image_id = response.data.image_id
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }
    },
    async uploadBannerVideo() {
      const formData = new FormData()
      formData.append('files', this.$refs.refInputElVideo.files[0])
      await useJwt.uploadBannerVideo(this.banner._id, formData)
        .then(response => {
          this.banner.video_source_id = response.data.image_id
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
                const field = err.source.pointer.split('/')
                this.$refs.validationSiteRules.setErrors({ [field[field.length - 1]]: err.detail })
              })
            }
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
#i-date_num {
  display: none;
}
</style>
