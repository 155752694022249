<template>
  <div>
    <div v-if="sidebarData.length !== 0 && !sidebarLoading">
      <div
        v-if="Object.keys(sidebarData).length > 0"
        class="d-flex justify-content-between"
      >
        <strong>{{ $t(`stats.${sidebarDataField}`) }}:</strong>
        <strong v-if="sidebarDataField === 'revenue'">
          <div
            v-for="(currencyType, idx) in Object.entries(calculateTotalRevenue(sidebarData))"
            :key="idx"
          >
            {{ currency(currencyType[0], currencyType[1]) }}
          </div>
        </strong>
        <strong v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(countObjectTotals(sidebarData)) }}</strong>
      </div>
      <div
        v-else
        class="d-flex justify-content-between"
      >
        <strong>{{ $t(`stats.${sidebarDataField}`) }}:</strong>
        <strong v-if="sidebarDataField === 'revenue'">
          <div
            v-for="(currencyType, idx) in Object.entries(calculateTotalRevenue(sidebarData))"
            :key="idx"
          >
            {{ currency(currencyType[0], currencyType[1]) }}
          </div>
        </strong>
        <strong v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(sidebarData) }}</strong>
      </div>
      <div
        v-for="(item, idx) in Object.keys(sortObjectByValues(sidebarData))"
        :key="item"
      >
        <div
          v-if="item !== 'others'"
          class="stats-item d-flex justify-content-between"
          :class="{
            even: idx % 2 !== 0,
            odd: idx % 2 === 0
          }"
        >
          <template v-if="sidebarDataType === 'banner'">
            <small v-if="campaignUserIdByIdFromStore(item) === null">
              {{ campaignNameByIdFromStore(item) }}
            </small>
            <template v-else>
              <small
                :class="{'text-primary cursor-pointer': item !== 'others'}"
                @click="openRouteInNewWindow('dashboard-user-campaign', { id: item, userId: campaignUserIdByIdFromStore(item) })"
              >
                {{ campaignNameByIdFromStore(item) }}
              </small>
            </template>
          </template>
          <template v-else-if="sidebarDataType === 'cpcfeed'">
            <small
              :class="{'text-primary cursor-pointer': item !== 'others'}"
              @click="getSiteById(item)"
            >
              {{ campaignNameByIdFromStore(item) }}
            </small>
          </template>
          <template v-else-if="sidebarDataType === 'external'">
            <!-- @click="$router.push({ name: 'dashboard-partner', params: { id: item } })"/-->
            <small
              :class="{'text-primary cursor-pointer': item !== 'others'}"
              @click="openRouteInNewWindow('dashboard-partner', { id: item })"
            >
              {{ partnerNameByIdFromStore(item) }}
            </small>
          </template>
          <template v-else-if="sidebarDataType === 'prebid'">
            <!-- @click="$router.push({ name: 'dashboard-prebidpartner', params: { id: item } })" /-->
            <small
              :class="{'text-primary cursor-pointer': item !== 'others'}"
              @click="openRouteInNewWindow('dashboard-prebidpartner', { id: item })"
            >
              {{ item }}
            </small>
          </template>
          <template v-else>
            <small>
              {{ item }}
            </small>
          </template>
          <small v-if="sidebarDataField === 'revenue'">{{ currency(campaignCurrencyByIdFromStore(item), sidebarData[item]) }}</small>
          <small v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(sidebarData[item]) }}</small>
        </div>
      </div>
      <div
        v-if="sidebarDataType === 'external'"
        class="alert alert-primary mt-2"
      >
        <div class="alert-body">
          <span><strong>{{ $t(`stats.alerts.${sidebarDataType}`) }}</strong></span>
        </div>
      </div>
    </div>
    <div
      v-else-if="sidebarLoading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </div>

  </div>
</template>

<script>
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate from '@/utils/date-formatter'
import { mapGetters } from 'vuex'
import { getSiteById, openRouteInNewWindow } from '@/utils/helpers'

import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  props: {
    sidebarData: {
      type: Object,
      required: true,
    },
    accountCurrency: {
      type: String,
      default: '',
    },
    sidebarDataField: {
      type: String,
      default: '',
    },
    sidebarDataType: {
      type: String,
      default: '',
    },
    selectedRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      getFormattedDate,
      openRouteInNewWindow,
      getSiteById,
      sidebarLoading: false,
    }
  },
  computed: {
    ...mapGetters('dashboard-campaings-stats', ['campaignNameByIdFromStore', 'campaignUserIdByIdFromStore', 'campaignCurrencyByIdFromStore']),
    ...mapGetters('dashboard-sites-stats', ['siteNameByIdFromStore']),
    ...mapGetters('dashboard-partners-stats', ['partnerNameByIdFromStore']),
  },
  watch: {
    sidebarData(newValue) {
      if (newValue.length > 0) {
        this.sidebarLoading = false
      } else {
        this.sidebarLoading = true
        setTimeout(() => { this.sidebarLoading = false }, 1000)
      }
    },
  },
  methods: {
    countObjectTotals(obj) {
      const calc = Object.values(obj).reduce((acc, value) => acc + value, 0)
      return calc.toFixed(2)
    },
    sortObjectByValues(obj) {
      const entries = Object.entries(obj)
      const othersEntry = entries.find(entry => entry[0] === 'others')
      const filteredEntries = entries.filter(entry => entry[0] !== 'others')
      filteredEntries.sort((a, b) => b[1] - a[1])
      if (othersEntry) {
        filteredEntries.push(othersEntry)
      }

      return Object.fromEntries(filteredEntries)
    },
    calculateTotalRevenue(data) {
      const revenueByCurrency = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const [id, amount] of Object.entries(data)) {
        const currencyType = this.campaignCurrencyByIdFromStore(id)
        if (currencyType !== null) {
          if (!revenueByCurrency[currencyType]) {
            revenueByCurrency[currencyType] = 0
          }
          revenueByCurrency[currencyType] += amount
        }
      }
      return revenueByCurrency
    },
  },
}
</script>

<style scoped>
#modal-stats-totals .stats-item {
  padding: 4px;
  border-top: 1px solid #ebe9f1
}

#modal-stats-totals .stats-item.odd {
  background-color: #fafafc;
}

#modal-stats-totals .card .card-body .stats-item.even {
  background-color: #ffffff;
}
</style>
