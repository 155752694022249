var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
      'balance-right': _vm.campaignData.status === 'active',
      'balance-center': _vm.campaignData.status === 'inactive'
    }},[_c('div',{staticClass:"alert",class:{
        'alert-success': _vm.campaignData.status === 'active',
        'alert-warning': _vm.campaignData.status === 'inactive'
      }},[_c('div',{staticClass:"alert-body d-flex flex-nowrap",attrs:{"id":"campaign-balance"}},[_c('div',{staticClass:"card-text mb-0",class:{
            'text-success': _vm.campaignData.status === 'active',
            'text-warning': _vm.campaignData.status === 'inactive'
          }},[_vm._v(" "+_vm._s(_vm.$t('page.payments.balance'))+": "),_c('span',[_vm._v(_vm._s(_vm.currency(_vm.campaignData.currency, _vm.campaignData.balance)))]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('message.tableHeader.status'))+": "),_c('span',[_vm._v(_vm._s(_vm.$t(("campaigns." + (_vm.campaignData.status)))))])]),_c('b-dropdown',{attrs:{"variant":"link","right":"","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"variant":"primary","to":("/users/" + (_vm.$route.params.userId) + "/campaigns/" + (_vm.$route.params.id) + "/payments")}},[_c('feather-icon',{staticClass:"align-middle mr-50",attrs:{"icon":"CreditCardIcon","size":"16"}}),_c('span',[_vm._v(_vm._s(_vm.$t('page.payments.transferTo')))])],1),(_vm.campaignData.status === 'inactive')?_c('b-dropdown-item',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.changeStatus(_vm.campaignData)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlayCircleIcon","size":"16"}}),_c('span',[_vm._v(_vm._s(_vm.$t('form.turnon')))])],1):_c('b-dropdown-item',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.changeStatus(_vm.campaignData)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"StopCircleIcon","size":"16"}}),_c('span',[_vm._v(_vm._s(_vm.$t('form.turnoff')))])],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }