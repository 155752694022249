<template>
  <div>
    <div
      :class="{
        'balance-right': campaignData.status === 'active',
        'balance-center': campaignData.status === 'inactive'
      }"
    >
      <div
        class="alert"
        :class="{
          'alert-success': campaignData.status === 'active',
          'alert-warning': campaignData.status === 'inactive'
        }"
      >
        <div
          id="campaign-balance"
          class="alert-body d-flex flex-nowrap"
        >
          <div
            class="card-text mb-0"
            :class="{
              'text-success': campaignData.status === 'active',
              'text-warning': campaignData.status === 'inactive'
            }"
          >
            {{ $t('page.payments.balance') }}: <span>{{ currency(campaignData.currency, campaignData.balance) }}</span>
            <br>
            {{ $t('message.tableHeader.status') }}: <span>{{ $t(`campaigns.${campaignData.status}`) }}</span>
          </div>
          <b-dropdown
            variant="link"
            right
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              variant="primary"
              :to="`/users/${$route.params.userId}/campaigns/${$route.params.id}/payments`"
            >
              <feather-icon
                icon="CreditCardIcon"
                size="16"
                class="align-middle mr-50"
              />
              <span>{{ $t('page.payments.transferTo') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="campaignData.status === 'inactive'"
              variant="success"
              @click="changeStatus(campaignData)"
            >
              <feather-icon
                icon="PlayCircleIcon"
                size="16"
                class="mr-25"
              />
              <span>{{ $t('form.turnon') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              variant="danger"
              @click="changeStatus(campaignData)"
            >
              <feather-icon
                icon="StopCircleIcon"
                size="16"
                class="mr-25"
              />
              <span>{{ $t('form.turnoff') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import currencyFormatter from '@/utils/currency-formatter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

export default {
  name: 'CampaignBalance',
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    campaignData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currency: currencyFormatter,
    }
  },
  computed: {
    isBalance() {
      return !!this.campaignData.balance > 0
    },
  },
  methods: {
    async changeStatus(campaign) {
      campaign.status = campaign.status === 'inactive' ? 'active' : 'inactive'
      campaign.startdate = campaign.startdate !== null && campaign.startdate !== '' ? new Date(campaign.startdate).toISOString() : campaign.startdate
      campaign.enddate = campaign.enddate !== null && campaign.enddate !== '' ? new Date(campaign.enddate).toISOString() : campaign.enddate

      if (campaign.targetings.date.params !== undefined) {
        campaign.targetings.date.params.startdate = new Date(campaign.targetings.date.params.startdate).toISOString()
        campaign.targetings.date.params.enddate = new Date(campaign.targetings.date.params.enddate).toISOString()
      }

      if (campaign.balance <= 0) {
        this.$swal({
          title: 'Please top up balance',
          text: 'Not enough balance to activate',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (campaign.status === 'active') {
        useJwt.setCampaign(campaign._id, { campaign })
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Activation!',
              text: 'Successfully activated.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(error => {
            campaign.status = 'inactive'
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.length) {
                error.response.data.errors.forEach(err => {
                  const field = err.source.pointer.split('/')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: `${field[field.length - 1]}: ${err.detail}`,
                    },
                  })
                })
              }
            }
          })
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            campaign.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setCampaign(campaign._id, { campaign })
        })
      }
    },
  },
}
</script>
<style scoped>
#campaign-balance {
  padding-right: 0;
}

#campaign-balance .dropdown-toggle {
  color: #1b874b
}

.balance-right .alert {
  position: absolute;
  right: 55px;
  top: 40px;
  z-index: 1;
  margin-bottom: 0;
}

.balance-center {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

@media (max-width: 768px) {
  .balance-right {
    display: flex;
    justify-content: center;
    margin: 15px 0 0;
  }

  .balance-right .alert {
    position: static;
    right: 0;
    top: 0;
  }
}
</style>
