import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORAGE_KEY = 'campaignNames'
const TIMESTAMP_KEY = 'campaignNamesTimestamp'
const EXPIRATION_TIME = 60 * 60 * 1000

export default {
  namespaced: true,
  state: {
    campaignNames: JSON.parse(localStorage.getItem(STORAGE_KEY)) || [],
  },
  getters: {
    campaigns: state => {
      const lastSavedTime = localStorage.getItem(TIMESTAMP_KEY)
      const timestamp = Number(lastSavedTime)
      const currentTime = Date.now()

      if (!lastSavedTime || Number.isNaN(timestamp) || currentTime - timestamp > EXPIRATION_TIME) {
        return []
      }

      return state.campaignNames
    },
    campaignNameByIdFromStore: state => campaignId => {
      const lastSavedTime = localStorage.getItem(TIMESTAMP_KEY)
      const timestamp = Number(lastSavedTime)
      const currentTime = Date.now()

      if (!lastSavedTime || Number.isNaN(timestamp) || currentTime - timestamp > EXPIRATION_TIME) {
        return campaignId
      }

      const campaign = state.campaignNames.find(s => s.id === campaignId)
      return campaign ? campaign.name : campaignId
    },
    campaignUserIdByIdFromStore: state => campaignId => {
      const lastSavedTime = localStorage.getItem(TIMESTAMP_KEY)
      const timestamp = Number(lastSavedTime)
      const currentTime = Date.now()

      if (!lastSavedTime || Number.isNaN(timestamp) || currentTime - timestamp > EXPIRATION_TIME) {
        return null
      }

      const campaign = state.campaignNames.find(s => s.id === campaignId)
      return campaign ? campaign.user_id : null
    },
    campaignCurrencyByIdFromStore: state => campaignId => {
      const lastSavedTime = localStorage.getItem(TIMESTAMP_KEY)
      const timestamp = Number(lastSavedTime)
      const currentTime = Date.now()

      if (!lastSavedTime || Number.isNaN(timestamp) || currentTime - timestamp > EXPIRATION_TIME) {
        return null
      }

      const campaign = state.campaignNames.find(s => s.id === campaignId)
      return campaign ? campaign.currency : null
    },
  },
  mutations: {
    ADD_CAMPAIGNS_NAME(state, newCampaigns) {
      const currentIds = new Set(state.campaignNames.map(campaign => campaign.id))

      newCampaigns.forEach(newCampaign => {
        if (!currentIds.has(newCampaign.id)) {
          state.campaignNames.push(newCampaign)
        }
      })

      localStorage.setItem(STORAGE_KEY, JSON.stringify(state.campaignNames))
      localStorage.setItem(TIMESTAMP_KEY, Date.now().toString())
    },
  },
  actions: {
    async fetchCampaignNamesById({ commit, state }, ids) {
      const lastSavedTime = localStorage.getItem(TIMESTAMP_KEY)
      const timestamp = Number(lastSavedTime)
      const currentTime = Date.now()

      if (!lastSavedTime || Number.isNaN(timestamp) || currentTime - timestamp > EXPIRATION_TIME) {
        try {
          const response = await useJwt.campaignNamesById(ids)
          commit('ADD_CAMPAIGNS_NAME', response.data)
        } catch (error) {
          if (error.response && error.response.status === 422 && error.response.data.errors.length) {
            error.response.data.errors.forEach(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: err.detail,
                },
              })
            })
          }
        }
      } else {
        const missingIds = ids.filter(id => !state.campaignNames.some(campaign => campaign.id === id))

        if (missingIds.length === 0) {
          return
        }

        try {
          const response = await useJwt.campaignNamesById(missingIds)
          commit('ADD_CAMPAIGNS_NAME', response.data)
        } catch (error) {
          if (error.response && error.response.status === 422 && error.response.data.errors.length) {
            error.response.data.errors.forEach(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: err.detail,
                },
              })
            })
          }
        }
      }
    },
  },
}
